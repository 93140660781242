.menu {
    background-color: #FFFFFF;
    font-size: calc(10px + 1vmin);
    padding: 5px;
}

span {
    margin: 0px 5px;
}

span.divider {
    width: 1px;
    height: 16.5px;
    margin: 12px 0px 0px;
    background: #78A498;
}

a.menu-item {
    color: #184A62;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    font-family: Inter;
}

.navbar-nav.navbar-nav-scroll {
    max-height: unset !important;
    overflow: hidden;
    margin-left: auto;
    margin-right: 0;
}

.navbar-collapse.collapse.show>.navbar-nav.navbar-nav-scroll>span.divider {
    width: 0px;
    margin: 0px;
}

/* @media (min-width: 992px){
.navbar-expand-lg .navbar-nav {
    flex-direction: row-reverse !important;
}
} */


@media only screen and (max-width: 992px) {
    .navbar-toggler.collapsed {
        visibility: hidden;
    }

    .logo-img{
        margin: auto;
    }
}