.header {
    text-align: right;
    background-color: #184A62;
    height: 52px;
    font-size: calc(10px + 1vmin);
    color: #fff;
    padding: 10px;
}

.btn-link,
label {
    margin: auto;
}

.phone-no,
.btn-link {
    text-decoration: none !important;
    color: #FFF !important;
    font-size: 17px;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.btn-link:hover, .btn-link:active{
    color: #FFF !important;
}

@media only screen and (max-width: 800px) {
    .header {
        text-align: center;
    }
}